.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.innerContainer {
  height: max-content;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 380px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.scrollContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.receiptContainerWrapper {
  margin-top: 20px;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 380px;
    max-width: 100vw;
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 20px);
  }
}

.triangleTop {
  width: 0;
  height: 0;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: rgba(250, 250, 250, 1);
  border-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 4px;
}

.triangleBottom {
  width: 0;
  height: 0;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: rgba(250, 250, 250, 1);
  border-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 4px;
}

.triangleContainer {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 440px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.downloadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin-bottom: 20px;
  max-width: min-content;
  height: 30px;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #F2F2F2;
  cursor: pointer;
}

.downloadText {
  font-size: 20px;
}

.errorMsgContainer {
  padding: 10%;
  margin: 5%;
  border-radius: 15px;
  background-color: rgba(255,255,255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timerMsg {
  font-size: small;
}

.errorMsg {
  font-size: large;
  text-align: center;
}

.timerDots {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 5%;
}
