.header {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  min-width: 100vw;
  background: url("../../assets/background.png") no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.content {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100vw;
  background-color: white;
}

.storeLogoContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.storeLogoWrapper {
  height: 50px;
  width: 150px;
  border-radius: 15px;
  overflow: hidden;
  margin: 10px;
  border: 1px white solid;
}

.storeLogo {
  height: 100%;
  width: 100%;
}

.logo {
  height: 40px;
}
